/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i");

@import "./scss/colors.scss";
@import "./scss/messages";

// this has to be before the boostrap import
$theme-colors: (
  "primary": $color-steel-blue,
);

/* Importing Bootstrap SCSS file. */
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "@angular/cdk/overlay-prebuilt.css";
@import "@angular/cdk/a11y-prebuilt.css";

/* Import Quill Scss files */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

body {
  background-color: $color-gallery;
  padding-top: 50px;
  font-family: "Open Sans", sans-serif;
}

h1,
.h1 {
  font-size: 2rem;
}

h2,
.h2 {
  font-size: 1.75rem;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.25rem;
}

h5,
.h5 {
  font-size: 1rem;
}

.error {
  color: $color-cinnabar;
}

input.error {
  border: 1px solid $color-cinnabar;
}

.btn-link {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.btn-primary.box-shadow {
  box-shadow: 2px 3px 0px $color-blumine;
}

.btn-danger.box-shadow {
  box-shadow: 2px 3px 0px $color-shiraz;
}

.spin::before {
  animation: spin 1050ms .5s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

// These styles are for the ng-bootstrap components because component styles don't affect them.
ngb-accordion {
  .goal-instruction {
    background-color: $color-alice-blue  !important;
  }

  .edit-goal-dimension-card {
    .card-header {
      background-color: #fff;
      border-bottom: none;
    }

    .card-body {
      padding-top: .25em;
    }
  }
}

// these styles are related to styling nxInput's and nxPopover's in 'edit-goal-according.component.html`
// it needs to be in global css otherwise the package's styles override the local component css
// nx-word__inner-wrapper is a class on the div that is the direct parent element of the input
// -CS
.nx-word__inner-wrapper {
  border-color: #27abd6 !important;
}

.invalid-nxInput .nx-word__inner-wrapper {
  border-color: $color-cinnabar  !important;
}

.nx-popover {
  &__content {
    background-color: #ffffff !important;
    color: $color-abbey  !important;
    border: 1px solid $color-abbey  !important;
    font-weight: bold !important;
    padding: 15px !important;
  }

  &__arrow {
    border-color: $color-abbey  !important;
    bottom: -6px !important;
    box-shadow: -2px -2px 4px rgb(65 65 65 / 33%) !important
  }
}

::ng-deep .add-email-address-modal {

  .model-content {

    //.address-form {
    label {

      //&.block {
      display: block !important;
      padding: 5px 10px;

      .radio {
        padding-right: 5px;
      }

      //}
    }

    //}
  }
}

::ng-deep .preview-mailing-modal {

  .model-content {
    .email-preview-body {
      font-family: Times New Roman;
      font-size: 12px;
      border: solid 1px #7F9DB9;
      background-color: #FFFFFF;
      padding: 10px;
      margin: 6px;
    }
  }
}

//.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
//.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
//  content: attr(data-value) !important;
//}

//QuillJS doesn't like duplicates, so using pt conversion of 12px for Default
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="9pt"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9pt"]::before {
  content: 'Default' !important;
  font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="8px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: attr(data-value) !important;
  font-size: 8px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-snow .ql-size .ql-picker-item[data-value="10px"]::before {
  content: attr(data-value) !important;
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="11px"]::before,
.ql-snow .ql-size .ql-picker-item[data-value="11px"]::before {
  content: attr(data-value) !important;
  font-size: 11px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-size .ql-picker-item[data-value="12px"]::before {
  content: attr(data-value) !important;
  font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-size .ql-picker-item[data-value="14px"]::before {
  content: attr(data-value) !important;
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-size .ql-picker-item[data-value="16px"]::before {
  content: attr(data-value) !important;
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-size .ql-picker-item[data-value="18px"]::before {
  content: attr(data-value) !important;
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-size .ql-picker-item[data-value="24px"]::before {
  content: attr(data-value) !important;
  font-size: 24px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-size .ql-picker-item[data-value="32px"]::before {
  content: attr(data-value) !important;
  font-size: 32px !important;
}

  .ql-toolbar button.ql-active {
    color: #06c;
  }
// Checkbox container - to get everything to align correctly
.cb {
  vertical-align: middle;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.ql-editor strong {
font-weight: "bold",
}

.email-preview-body em{
  font-style: italic !important;
}
.ql-align-right {
	text-align: right;
}
.ql-align-center {
	text-align: center;
}
.ql-align-left {
	text-align: left;
}
.ql-editor  em {
  font-style: italic !important;
}
::ng-deep .ql-editor  em {
  font-style: italic !important;
}
.ql-editor u {
text-decoration: "underline",
}

.email-preview-body em{
  font-style: italic !important;
}

.email-preview-body .ql-hr {
  border : none;
  margin:10px 0;
  border-top: 2px solid #444!important;
}

.ql-hr {
  border : none;
  margin:10px 0;
  border-top: 2px solid #444!important;
}