// Color naming based off of http://chir.ag/projects/name-that-color/
$color-abbey: #4d4f51;
$color-silver: #cccccc;
$color-alto: #d1d1d1;
$color-alto-light: #d5d5d5;
$color-gallery: #ececec;
$color-white: #FFF;
$color-alice-blue: #F0F8FF;
$color-botticelli: #C7DDE5;
$color-polo-blue: #8DA8CC;

$color-cinnabar: #e44e41;
$color-shiraz: #B20931; // Used as box-shadow for danger button
$color-crusta: #F8873F;
$color-atlantis: #66CC33;
$color-jelly-bean-faded: #619DB4;
$color-steel-blue: #447ba9;
$color-jelly-bean: #1E7395;
$color-blue-violet: #6456B7;
$color-blumine: #18587A; // Used as box-shadow for primary button