.messages {
  align-items: baseline;
  background-color: $color-white;
  color: $color-abbey;
  display: flex;
  font-weight: bold;
  letter-spacing: 0.1rem;
  margin: 0;
  position: fixed;
  width: calc(100% - 2rem);
  font-size: 1rem;
  z-index: 1002;
  border: 1px solid $color-alto;
  border-radius: 0 0 0.3rem 0.3rem;
  top: 6rem;
  right: 2rem;
  max-width: 50rem;
  text-align: left;
  box-shadow: 5px 5px 2px rgba(0, 0, 0, 0.05);
  padding: 1.5rem 3.5rem 1.5rem 1.5rem;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateX(33em);
  -moz-transform: translateX(33em);
  -ms-transform: translateX(33em);
  -o-transform: translateX(33em);
  transform: translateX(33em);
  opacity: 0;

  &.slideIn {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }

  &.danger {
    border-top: 5px solid $color-cinnabar;

    .icon {
      color: $color-cinnabar;
    }
  }

  &.success {
    border-top: 5px solid $color-atlantis;

    .icon {
      color: $color-atlantis;
    }
  }

  &.info {
    border-top: 5px solid $color-jelly-bean-faded;

    .icon {
      color: $color-jelly-bean-faded;
    }
  }

  &.warning {
    border-top: 5px solid $color-crusta;

    .icon {
      color: $color-crusta;
    }
  }

  .close {
    position: absolute;
    right: 2rem;
    top: 1.2rem;
    color: $color-abbey;
  }

  .message-icon {
    padding: 0 2rem 0 0;
  }
}

.no-data {
  border: 2px dotted #ddd;
  display: block;
  padding: 1.5rem;
  position: relative;
  min-height: 30rem;

  &__content {
    left: 1.5rem;
    position: absolute;
    right: 1.5rem;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }
}
